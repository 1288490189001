<template>
  <div class="LinePayCallbackCancel">
    <div
      v-if="showAlert"
      class="light-box flex-container flex-center center flex-center-h"
      @click.self="showBindAlert=false"
    >
      <div class="box flex-container flex-column flex-center center">
        <span class="msg text-1250">LINE PAY綁定失敗</span>
        <button
          type="button"
          class="capsule-button text-875 capsule-small check"
          @click="$_lightBoxOnpress()"
        >
          回首頁
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "LinePayCallbackCancel",
    data(){
        return{
            showAlert:true
        }
    },
    methods: {
        $_lightBoxOnpress(){
            this.showAlert = false;
            this.$router.push("/map");
        },
    },
};
</script>
<style lang="scss">

</style>